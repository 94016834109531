<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="five"></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <div class="titleTopCenterLeftBox">回访调查</div>
        <div class="titleTopCenterLeftBox" @click="goup">返回上一步</div>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop">
          <div class="iq-card-body">
            <div class="table-content">
              <div class="top-info">
                <div>回访调查</div>
                <div class="topinfoDetail">
                  <div>尊敬的用户：</div>
                   您好！为了不断提升我们的服务质量，特进行此次回访调查。请您根据自己的实际体验，在以下问题中进行选择或填写。非常感谢您的支持与配合！
                </div>
              </div>
              <div v-if="orderTrue == 0">
                  <div v-for="(item,index) in qRcodeUrlZ" :key="index" class="topinfoques">
                      <div><span style="color:#FF0000">*</span>{{item.question}}</div>
                      <div style="margin-left:20px;margin-top:10px;" v-for="(item1,index1) in item.options" :key="index1">
                        <el-radio-group v-model="item.answer">
                            <el-radio :label="item1.choose">{{item1.choose}}</el-radio>
                        </el-radio-group>
                      </div>
                      <div class="flex-l topinfoques1" style="margin-top:20px;margin-left:20px">其他情况：
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            style="width:60%"
                            v-model="item.answerDes">
                        </el-input>
                      </div>
                  </div>
                  <div style="margin-top:40px;margin-left: 50px;">
                      再次感谢您的宝贵意见！祝您身体健康！
                  </div>
                  <div  style="margin:40px auto;text-align: center;">
                    <el-button type="primary" style="background:#5A83E5" @click="gosub">提交</el-button>
                  </div>
              </div>
              <div v-else>
                <div v-for="(item,index) in qRcodeUrlZData" :key="index" class="topinfoques" >
                    <div><span style="color:#FF0000">*</span>{{item.question}}</div>
                    <div class="flex-l topinfoques1" style="margin-top:20px;margin-left:20px">反馈详情：
                      <span>{{item.answer}}</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import { Outpatient } from "@/components/Drug/Outpatient";
export default {
  components: {
    intNavheader,
  },
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var outpatientAn = new Outpatient(this.TokenClient, this.Services.Authorization);
    return {
      OutpatientDomain: outpatient,
      OutpatientDomainan:outpatientAn,
      userInfo: this.$store.state.localData.userInfo,
      orderId:parseInt(this.$route.query.orderId),
      qRcodeUrlZ:[{
          question:'1.用药后症状是否有所缓解？',
          answer:'',
          answerDes:'',
          options:[{
            choose:'已治愈'
          },
          {
            choose:'明显缓解'
          },
           {
            choose:'部分缓解'
          },
           {
            choose:'无明显变化'
          }]

      },
      {
          question:'2.您对我们的服务态度是否满意？',
          answer:'',
          answerDes:'',
          options:[{
            choose:'非常满意'
          },
          {
            choose:'满意'
          },
           {
            choose:'一般'
          },
          {
            choose:'不满意'
          }]

      },
         {
          question:'3.您对药品配送速度是否满意？',
          answer:'',
          answerDes:'',
          options:[{
            choose:'非常满意'
          },
          {
            choose:'满意'
          },
           {
            choose:'一般'
          },
          {
            choose:'不满意'
          }]

      }],
      qRcodeUrlZData:[],
      orderTrue:this.$route.query.orderTrue ? this.$route.query.orderTrue : 0
    };
  },
  created(){
    if (this.$route.query.orderTrue == '1') {
      this.getMZConfirmFeedbackInfoData1()
    }
  },
  methods: {
    goup(){
      this.$router.back(-1)
    },
    getMZConfirmFeedbackInfoData1(){
      var _this = this
        _this.OutpatientDomain.getMZConfirmFeedbackInfo(_this.$route.query.orderId,
        function(data){
          _this.qRcodeUrlZData = data.data.feedbackInfo
        }
      )
    },
    gosub(){
      var _this = this
      let params = {
        feedbackInfo:[],
        orderId:''
      }
      for (let i =0; i<_this.qRcodeUrlZ.length; i++) {
        if (_this.qRcodeUrlZ[i].answer == '' && _this.qRcodeUrlZ[i].answerDes == '') {
             _this.$message({
                message: '请回答' + i + '题',
                type: 'warning'
              });
              return
        } else {
          params.feedbackInfo.push({
            question: _this.qRcodeUrlZ[i].question,
            answer:_this.qRcodeUrlZ[i].answer + ',' + _this.qRcodeUrlZ[i].answerDes,
          })
        }
     
      }
      params.orderId = _this.orderId
      _this.OutpatientDomain.AddMZConfirmFeedback(params,
        function (data) {
          _this.OutpatientDomainan.MZPatientIdDetail(_this.$route.query.patientId,function(data){
              let userId = data.data ?  data.data : 0
              _this.OutpatientDomainan.AddAddPatientPoint(userId,_this.$route.query.orderNum,'20',
              function (data) {
              console.log(data)
              _this.$message({
                    message: '提交成功',
                    type: 'success'
                  });
                  _this.$router.push({
                    name: "orderList",
                  })
              },
              function (error) {
                  console.log(error)
                  _this.$message({
                    message: '提交成功',
                    type: 'success'
                  });
                  _this.$router.push({
                    name: "orderList",
                  })
              }
            );
          },
          function(err){
            console.log(err);
          })
        },
        function (err) {
          console.log(err);
        }
      );

    }
  },
};
</script>
<style scoped lang="scss">
.pageBox {
  text-align: center;
  margin-top: 10px;
}

.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}

.titleTopLeftImg {
  width: 123px;
  height: 24px;
}

.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}

.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}

.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}

.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}

.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.titleTopRight div {
  margin-left: 10px;
}

.titleTopLine {
  width: 100%;
  height: 12px;
}

.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}

.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  // max-height: 750px;
  overflow: auto;
}

.noflex-l-s {
  display: flex;
  justify-content: space-around;
}

.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}

.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  height: calc(100vh - 64px - 24px);
  overflow: auto;
}

.titleTopCenterCenterTop {
  background-color: #ffffff;
  // padding: 0 40px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  height: 100%;
  overflow-y: auto;
}

.flex-l {
  display: flex;
}

.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.noflex-l-s {
  display: flex;
  justify-content: space-around;
}

.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Content1Title {
  padding: 20px 0;
  padding-left: 0;
  font-size: 18px;
  height: calc(100% - 20px);
  /* font-weight: bold; */

  color: #333333;
  padding-bottom: 0;
  text-align: left;
}

.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}

.ageInput {
  width: 80%;
}

.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}

.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
}

.titleTopright1Line {
  border-bottom: 1px dashed #999999;
}

.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}

.stepInput {
  width: 60%;
  margin-right: 10px;
}

.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  /* margin-top: 0; */
}

.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  /* margin-top: 0; */
}

.el-page-header {
  line-height: 73px;
  /* padding-left: 20px; */
}

.iq-card-body {
  padding: 0 0 15px;
  height: auto;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }
  .table-content {
    padding: 0 40px;
 

  }
}

  .top-info {
    font-weight: bold;
    font-size: 24px;
    color: #5A83E5;
    text-align: center;
    margin-top: 35px;
  }
  .topinfoDetail {
    font-weight: bold;
    font-size: 24px;
    color: #5A83E5;
    text-align: center;
    margin-top: 35px;
  }
  .topinfoDetail {
    margin-top: 5px;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    padding-bottom: 20px;
    border-bottom: 1px dashed #cccccc;
  }
  .topinfoques {
    margin-top: 25px;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-left: 50px;
  }
    .topinfoques1 {
    margin-top: 25px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
</style>
